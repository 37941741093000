body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

.darkcyan {
  color: darkcyan;
}

.darkred {
  color: darkred;
}

.white {
  color: white;
}

.green {
  color: #7FB03A !important;
}

.orange {
  color: #E0BE36 !important;
}

.bold {
  font-weight: bold !important;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 480px;
  background-color: #003366;
  color: white;
}

.terms-conditions {
  color: rgba(255, 255, 255, 0.75);
}

.img {
  align-self: center;
  height: 83vh;
  min-height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img > img {
  height: 76%;
  max-height: 250px;
  margin-top: 24px;
  align-self: center;
}

.img > h2 {
  font-size: 2em;
  margin: 0;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  min-height: 300px;
  width: 97vw;
  max-width: 900px;
  font-size: 1.4em;
  margin-left: 5px;
  margin-right: 5px;
}

.field {
  display: flex;
  flex-direction: column;
}

.field > input {
  height: 6vh;
  min-height: 33px;
  border: solid 1px #ccc;
  border-radius: 5px;
  font-size: 1em;
}

#password {
  font-size: 1.4em;
}

.btn {
  background-color: #7FB03A;
  height: 6vh;
  min-height: 33px;
  color: inherit;
  font-size: 1em;
  border: solid 1px #7FB03A;
  border-radius: 5px;
}

.header-bar {
  display: flex;
  height: 10vh;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3em;
  background-color: #003366;
  color: white;
  box-shadow: 0px 1px 1px 1px #999;
}

.menu-btn {
  padding: 3vh;
  cursor: pointer;
}

.brand {
  position: absolute;
  right: 43%;
}

.contenido {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  top: 0;
  bottom: 0;
  transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
}

.contenido.animate {
  left: 83%;
  right: -83%;
}

.menu {
  position: absolute;
  left: -83%;
  right: 100%;
  min-width: 83%;
  min-height: 100%;
  background-color: #333;
  color: white;
  background-image: url('./overlay.svg'), url('./city.jpg');
  background-size: cover;
  opacity: 0.1;
  z-index: 1000;
  transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
}

.menu.animate {
  opacity: 1;
  right: 17%;
  left: 0;
  box-shadow: 1px 1px 1px 1px #333;
}

.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  list-style-type: none;
  min-height: 100vh;
  height: 100%;
  padding: 0;
  margin: 0;
}

.menu-items > li > .item {
  display: block;
  transform: translateX(-4em) rotate(-90deg);
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  font-size: 1.3em;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
}

.menu-items > li > .item.animate {
  transform: translateX(0);
  left: auto;
  right: auto;
}

.menu-items > li:hover {
  text-decoration: underline;
}

.modal {
  position: relative;
  left: 2.5vw;
  top: 2.5vh;
  min-height: 95vh;
  width: 95vw;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  z-index: 1001;
}

.modal > .bar {
  height: 8vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  color: #777;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

.modal-content > h3 {
  text-align: center;
}

.modal-item {
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
}

.bg-black {
  background-color: rgba(70,70,70,0.75);
}

.bring-front {
  z-index: 1500;
}

.promt {
  position: absolute;
  height: 20vh;
  width: 98vw;
  top: 20vh;
  left: 1vw;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.options > .btn {
  width: 35vw;
  text-align: center;
  font-size: 1.3em;
}

.options > .btn-no {
  background-color: #999;
  border-color: #999;
}

.options > .btn-yes {
  color: white;
}

.modal-content > .options {
  margin-top: 10vh;
  position: relative;
  bottom: 0;
}

.modal-content > strong {
  margin-top: 30px;
}

/* Animations */

.modal-enter {
  opacity: 0.01;
}

.modal-enter.modal-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  z-index: 1001;
}

.modal-exit {
  opacity: 1;
}

.modal-exit.modal-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
  z-index: 1001;
}

.modal-item-enter {
  opacity: 0.01;
  transform: translateX(-100vw);
}

.modal-item-enter.modal-item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in;
  z-index: 1001;
}

.modal-item-exit {
  opacity: 1;
}

.modal-item-exit.modal-item-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
  z-index: 1001;
}

.promt-enter {
  opacity: 0.01;
}

.promt-enter.promt-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  z-index: 1500;
}

.promt-exit {
  opacity: 1;
}

.promt-exit.promt-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
  z-index: 1500;
}

/**************/
/*  MapScreen */
/**************/
.geofence-map{
  position: relative;
  height: 100%;
  width: 100%;
}
.map-container{
  display: block;
  position: relative;
  height: 100%;
  height: 100vh;
  margin-top: 0px;
  overflow: hidden;
}
.map-container.map-screen{
  height: calc(100vh - 96px);
}
.streetview .map-container.map-screen{
  height: calc(100vh - 56px);
}
@media screen and (min-width: 600px) {
  .map-container.map-screen{
    height: calc(100vh - 104px);
  }
  .streetview .map-container.map-screen{
    height: calc(100vh - 64px);
  }
}
@media (max-width: 600px) and (orientation: landscape){
  .streetview .map-container.map-screen{
    height: calc(100vh - 48px);
  }
}

/* GEOFENCE SCREEN  */
/* .map-container.geofence-screen{
  height: 300px;
} */
.map-container.geofence-screen {
  min-height: 270px;
  height: calc(100vh - 210px);
}

/* @media screen and (min-width: 600px) {
  .map-container.geofence-screen {
    height: calc(100vh - 242px);
  }
}

@media (max-width: 600px) and (orientation: landscape){
  .map-container.geofence-screen {
    height: calc(100vh - 226px);
  }
} */

/* RutaScreen */
.ruta-options {
  height: 50vh;
  overflow-y: scroll;
}

.ruta-options-container {
  display: flex;
  flex-direction: column;
}

.ruta-options-container > div > .selector {
  height: 10vh;
  background-color: #7FB03A;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruta-options-container > div > .selector > .btn-left {
  height: 100%;
  background-color: transparent;
  width: 25vw;
  display: flex;
  justify-content: center;
  font-size: 1.3em;
}

.ruta-options-container > div > .selector > .btn-left-disabled {
  height: 100%;
  background-color: transparent;
  width: 25vw;
  display: flex;
  justify-content: center;
  font-size: 1.3em;
  color: rgba(200,200,200,0.5);
}

.ruta-options-container > div > .selector > .btn-right {
  height: 100%;
  background-color: transparent;
  width: 25vw;
  display: flex;
  justify-content: center;
  font-size: 1.3em;
}

.ruta-options-container > div > .selector > .btn-right-disabled {
  height: 100%;
  background-color: transparent;
  width: 25vw;
  display: flex;
  justify-content: center;
  font-size: 1.3em;
  color: rgba(200,200,200,0.5);
}

.ruta-options-container > div > .selector > .date-selector {
  background-color: transparent;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ruta-options-container > div > .selector > .date-selector > .dia {
  font-size: 1.25em;
}

.ruta-options-container > div > .selector > .date-selector > .fecha {
  font-size: 2.5em;
}

.ruta-options-container > div > .selector > .date-selector > .month-year {
  display: flex;
  flex-direction: column;
}

/* .ruta-options-container > div > .btn-consulta {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
  border: solid 10px #7FB03A;
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  color: #2E8D4E;
  height: 10vh;
}

.ruta-options-container > div > .btn-consulta-hidden {
  display: none;
} */

.ruta-options-container > .table > .ruta-item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #003366;
  font-size: 0.9em;
}

.ruta-options-container > .table > div > .ruta-item.total {
  display: flex;
  justify-content: space-between;
  background-color: #003366;
  color: white;
  font-size: 0.9em;
}

.ruta-options-container > .table > .ruta-item > .item-time {
  padding-left: 3px;
  min-width: 16vw;
}

.ruta-options-container > .table > .ruta-item > .item-state {
  min-width: 37vw;
}

.ruta-options-container > .table > .ruta-item > .item-total {
  padding-right: 3px;
  min-width: 37vw;
}

.ruta-options-container > .totales {
  background-color: #003366;
  color: white;
  padding-left: 3px;
}

/* END RutaScreen */


.street-image {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.street-image > span.text {
  color: #d09b0c;
  font-weight: bold;
  text-decoration: underline;
}

.street-view-icon {
  color: #f3bf33;
}

.menu-bottom-right {
  align-self: flex-end;
}

.menu-bottom-right > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #800;
  background-color: rgba(179, 0, 0, 0.45);
  padding: 5px;
  align-items: center;
  text-align: center;
  text-decoration: underline;
}

.pac-container:after {
  /* REMOVE POWERED BY GOOGLE MARK */
  background-image: none !important;
  height: 0px;
}

@media (width < 600px) {
  #filterbar-1 {
    display: none !important;
  }
  #filterbar-button {
    display: none !important;
  }
}
